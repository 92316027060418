import { OrbitControls, OrthographicCamera, PivotControls, TransformControls } from "@react-three/drei";
import { useRef } from "react";

const particleCount=1000
const positions = new Float32Array(particleCount * 3);
for(let i = 0; i < particleCount *3; i++) {
  positions[i] = (Math.random() - 0.5) *10
}

export default function Experience()
{
  const cubeRef = useRef();

    return <>
      <OrbitControls makeDefault />

      <directionalLight position={ [ 2, 2, 3 ] } intensity={ 4.5 } color="red" />
      {/* <ambientLight intensity={ 1.5 } /> */}

      <points position-x={-4.1}>
        <sphereGeometry args={[1, 32, 32]}/>
        <pointsMaterial size={0.02} sizeAttenuation={true} color={"red"} />
      </points>

      <points position-x={-2}>
        <sphereGeometry args={[1, 32, 32]}/>
        <pointsMaterial size={0.02} sizeAttenuation={true} color={"red"} />
      </points>

      <mesh position-x={-2}>
        <sphereGeometry args={[1, 32, 32]}/>
        <meshBasicMaterial wireframe/>
      </mesh>

      <mesh position-x={0.1}>
        <sphereGeometry args={[1, 32, 32]}/>
        <meshBasicMaterial/>
      </mesh>

      <mesh position-x={2}>
        <boxGeometry args={[1, 1, 1]}/>
        <meshToonMaterial />
      </mesh>

      <mesh position-x={8} scale={0.04}>
        <torusKnotGeometry args={[10, 3, 32, 32]}/>
        <meshBasicMaterial color="green" wireframe />
      </mesh>
      
      <mesh position-x={6} scale={0.04}>
        <torusKnotGeometry args={[10, 3, 128, 128]}/>
        <meshBasicMaterial color="green" />
      </mesh>

      <mesh position-x={4} scale={0.04}>
        <torusKnotGeometry args={[10, 3, 128, 128]}/>
        <meshNormalMaterial />
      </mesh>

      {/* <points>
        <bufferGeometry>
          <bufferAttribute attach="attributes-position" array={positions} count={particleCount} itemSize={3}/>
        </bufferGeometry>
        <pointsMaterial color="green" size={0.075} sizeAttenuation={true}/>
      </points> */}

      <mesh position-x={-6}>
        <boxGeometry args={[1,1]} />
        <meshBasicMaterial color="green" />
      </mesh>

      {/* <mesh position-x={ 2 } scale={ 1.5 } ref={cubeRef}>
        <boxGeometry />
        <meshStandardMaterial color="mediumpurple" />
      </mesh>
      <TransformControls object={cubeRef}/>

      <mesh position-y={ - 1 } rotation-x={ - Math.PI * 0.5 } scale={ 10 }>
        <planeGeometry />
        <meshStandardMaterial color="greenyellow" />
      </mesh> */}
    </>
}